<template>
  <div>
    <not-usable-choices-drawer
      :opened="isDrawerOpened"
      :toggle="() => isDrawerOpened = !isDrawerOpened"
      :options="sortedColumns"
      :result-date="resultDate"
      :compare-date-with="compareDateWith"
      :handle-checkbox-click="handleCheckboxClick"
    ></not-usable-choices-drawer>
    <div class="not-usable-choices-comparison-page-header">
      <div
        class="not-usable-choices-comparison-page-header__label"
        role="button"
        tabindex="0"
        @click="handleBackToValidations"
      >
        <img
          src="@/assets/images/arrow-left.svg"
          alt="Back to validations page"
        />
        <span class="not-usable-choices-comparison-page-header__text">
          Back
        </span>
      </div>
    </div>
    <div>
      <div
        class="flex full-width justify-end items-center q-px-md q-pt-md q-pb-sm"
        :class="isDrawerOpened ?
          'not-usable-choices-comparison-page-header__drawer-opened'
          : 'not-usable-choices-comparison-page-header__drawer-closed'
        "
      >
        <template v-for="(caption, key) in notUsableChoicesComparisonTypes" :key="key">
          <span :class="caption.class"></span>
          <span
            :class="[
              'text-subtitle2 text-weight-regular q-ml-sm',
              key === notUsableChoicesComparisonTypes.MISSING_DATA.value ? 'q-mr-none' : 'q-mr-md'
            ]"
          >
            {{ caption.label }}
          </span>
        </template>
      </div>
      <not-usable-choices-table
        :columns="sortedColumns"
        :rows="table.rows"
        :loading="loading"
        :on-change-sort-table-column-visibility="onChangeSortTableColumnVisibility"
        :on-change-sort-table-direction="onChangeSortTableDirection"
      ></not-usable-choices-table>
      <div class="load-more-container">
        <mi-btn
          v-if="!loading && showLoadMoreChoicesButton"
          color="secondary"
          @click="loadMoreChoices"
        >
          Load more 500 choices
        </mi-btn>
        <span v-if="!showLoadMoreChoicesButton" class="text-subtitle2 text-weight-bold text-primary">
          You have reached the end of the list.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, watch } from 'vue'
  import { compareNotUsableChoices } from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import NotUsableChoicesTable from
    '@/components/validations/test-cases/not-usable-choices/NotUsableChoicesTable.vue'
  import NotUsableChoicesDrawer from '@/components/validations/test-cases/not-usable-choices/NotUsableChoicesDrawer.vue'
  import { TEST_CASES_ROUTE } from '@/router/routeNames'
  import { useTable } from '@/composables/useTable'
  import { formatDateStrDdMmYyyy } from '@/utils/formatDate'
  import {
    columns as defaultColumns,
    buildUrlParams, defaultPaginationParams,
    notUsableChoicesComparisonTypes
  } from './utils'

  export default {
    name: 'NotUsableChoicesComparison',
    components: { NotUsableChoicesTable, NotUsableChoicesDrawer },
    setup() {
      const route = useRoute()
      const router = useRouter()

      const currentSort = ref(null)
      const currentPagination = ref({ ...defaultPaginationParams })
      const loading = ref(false)

      const { table } = useTable(defaultColumns)
      const sortedColumns = ref(table.columns)

      const showLoadMoreChoicesButton = ref(true)
      const isDrawerOpened = ref(false)

      const fetchComparisonResults = (async () => {
        try {
          loading.value = true

          const params = buildUrlParams({
            sortBy: currentSort.value?.sortBy,
            sortDirection: currentSort.value?.sortDirection,
            limit: currentPagination.value?.limit,
            offset: currentPagination.value?.offset
          })

          return await compareNotUsableChoices({
            resultDate: route.query.resultDate,
            compareWith: route.query.compareDateWith,
            params
          })
        }
        catch (err) {
          console.error(err)
          return 0
        }
        finally {
          loading.value = false
        }
      })

      const handleBackToValidations = () => {
        router.push({ name: TEST_CASES_ROUTE.name })
      }

      const buildColumnDynamically = ({ label, headerKey, numberOfStateChanges }) => ({
        name: headerKey,
        label,
        field: headerKey,
        align: 'center',
        style: 'min-width: 200px;',
        isDynamic: true,
        checked: true,
        numberOfStateChanges
      })

      const handleCheckboxClick = filter => {
        sortedColumns.value = sortedColumns.value.map(column => {
          if (column.label === filter.label) {
            return { ...column, checked: !column.checked }
          }
          return column
        })
      }

      const buildTableStructure = (
        { headers = [], content = [], updateHeader = true, loadMoreChoices = false }
      ) => {
        if (!content.length) showLoadMoreChoicesButton.value = false

        if (updateHeader) {
          const customHeaders = headers.map(header => buildColumnDynamically(
            {
              label: `${ header.productModelName } ${ header.planningPeriod }`,
              headerKey: header.headerKey,
              checked: true,
              numberOfStateChanges: header.numberOfStateChangesAcrossAValidationComparison
            }
          ))
          table.columns = [...table.columns, ...customHeaders]
        }

        const newRows = content.map(({ cells, ...rest }, index) => ({
          index: loadMoreChoices ? table.rows.length + index : index,
          ...rest,
          optionName: `${ rest.optionId } - ${ rest.optionName }`,
          ...cells.reduce((acc, { headerKey, value }) => ({ ...acc, [headerKey]: value }), {})
        }))

        loadMoreChoices ? table.rows = [...table.rows, ...newRows] : table.rows = newRows
      }

      const onChangeSortTableColumnVisibility = (key, state) => {
        sortedColumns.value = sortedColumns.value.map(column => {
          if (column.name === key) {
            return { ...column, showSortElement: state }
          }
          return { ...column, showSortElement: false }
        })
      }

      const onChangeSortTableDirection = key => {
        const sortDirection = col => (col === 'DESC' ? 'ASC' : 'DESC')
        sortedColumns.value = sortedColumns.value.map(column => {
          if (column.name === key) {
            const direction = sortDirection(column.sortDirection)
            currentSort.value = { sortBy: key, sortDirection: direction }
            return { ...column, sortDirection: direction }
          }
          return { ...column, sortDirection: 'ASC' }
        })
      }

      const fetchComparisonAndCreateTableBoilerplate = async ({
        updateHeader = true,
        loadMoreChoices = false
      } = {}) => {
        const comparison = await fetchComparisonResults()
        buildTableStructure({
          headers: comparison.headers,
          content: comparison.content,
          updateHeader,
          loadMoreChoices
        })
      }

      const loadMoreChoices = async () => {
        if (!showLoadMoreChoicesButton.value) return

        currentPagination.value = {
          ...currentPagination.value,
          offset: currentPagination.value.offset + defaultPaginationParams.limit
        }

        await fetchComparisonAndCreateTableBoilerplate(
          { updateHeader: false, loadMoreChoices: true }
        )
      }

      onMounted(async () => {
        await fetchComparisonAndCreateTableBoilerplate()
      })

      watch(() => [currentSort.value], async () => {
        currentPagination.value = { ...defaultPaginationParams }
        showLoadMoreChoicesButton.value = true
        await fetchComparisonAndCreateTableBoilerplate(
          { updateHeader: false, loadMoreChoices: false }
        )
      })

      watch(() => [table.columns], () => {
        sortedColumns.value = table.columns
      })

      return {
        handleBackToValidations,
        table,
        loading,
        sortedColumns,
        onChangeSortTableColumnVisibility,
        onChangeSortTableDirection,
        currentSort,
        showLoadMoreChoicesButton,
        loadMoreChoices,
        currentPagination,
        isDrawerOpened,
        handleCheckboxClick,
        notUsableChoicesComparisonTypes,
        resultDate: formatDateStrDdMmYyyy(route.query.resultDate),
        compareDateWith: formatDateStrDdMmYyyy(route.query.compareDateWith)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .not-usable-choices {
    &__minus-circle-color {
      color: $mi-red-500;
    }

    &__check-circle-color {
      color: $mi-turquoise-600;
    }
  }

  .not-usable-choices-comparison-page-header {
    display: flex;
    width: 100%;
    padding: 14px;
    background-color: $mi-anthracite-50;

    &__drawer-closed {
      padding-right: 86px;
    }

    &__drawer-opened {
      padding-right: 304px;
    }

    &__label {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      padding-left: 10px;
    }

    &__text {
      color: $mi-anthracite-800;
      text-transform: uppercase;
      font-size: $mi-field-font-size;
      cursor: pointer;
    }
  }

  .load-more-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
  }
</style>
